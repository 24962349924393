import { css, keyframes } from '@amedia/brick-tokens';

// Animating the svg for icon: "pill-breaking"
const pulseBreaking = keyframes({
  '0%': {
    transform: 'scale(0.7)',
    boxShadow: '0 0 0 0 var(--_b-icon-color-pulse-start)',
  },
  '70%': {
    transform: 'scale(1)',
    boxShadow: '0 0 0 8px var(--_b-icon-color-pulse-end)',
  },
  '100%': {
    transform: 'scale(0.7)',
    boxShadow: '0 0 0 0 var(--_b-icon-color-pulse-end)',
  },
});

export const iconStyle = css({
  '@property --b-icon-color': `{
    syntax: "<color>";
    inherits: true;
    initial-value: currentColor;
  }`,
  '@property --b-icon-color-breaking-pulseStart': `{
    syntax: "<color>";
    inherits: true;
    initial-value: var(--brick-colors-pillNonePulseStartBg);
  }`,
  '@property --b-icon-color-breaking-pulseEnd': `{
    syntax: "<color>";
    inherits: true;
    initial-value: var(--brick-colors-pillNonePulseEndBg);
  }`,

  display: 'inline-flex',
  color: 'var(--b-icon-color, var(--_b-icon-color, currentColor))',

  '[data-icon-id="pill-breaking"] &': {
    '--_b-icon-color-pulse-start':
      'var(--b-icon-color-breaking-pulseStart, var(--brick--pulseColor70, var(--brick-colors-pillNonePulseStartBg)))',
    '--_b-icon-color-pulse-end':
      'var(--b-icon-color-breaking-pulseEnd, var(--brick--pulseColor0, var(--brick-colors-pillNonePulseEndBg)))',
    color:
      'var(--b-icon-color, var(--_b-icon-color, var(--brick-colors-pillNoneBreakingFg)))',
    borderRadius: '50%',
    animation: `${pulseBreaking} 2s infinite`,
    display: 'inline-flex',
    width: 'fit-content',

    // a11y
    '@media (prefers-reduced-motion)': {
      animation: 'none',
    },
  },
});

export const containerStyle = css({
  display: 'inline-flex',
});
